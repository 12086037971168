const PlaceSettingIcon: React.FC<IconProps> = ({
  className,
  width,
  height
}) => (
  <svg
    width={width || "83.213"}
    height={height || "64"}
    version="1.1"
    viewBox="0 0 22.017 16.933"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fill="currentColor"
      d={`
        m21.768-1.5256e-4s-1.9565 1.1021-1.9565 9.269c1.4994 0.41211 0.86868 2.0496 0.75435 2.258 2.61e-4 0.41594 0.03163 4.6873 0.03163 4.6873l0.0051-0.0021c-0.0021 4e-3 -0.0031 0.0088-0.0031 0.01376-0.0021 0.39227 0.31653 0.7103 0.70743 0.70743 0.38959 1e-3 0.70845-0.31764 0.70692-0.70692 0.0021-4e-3 0.0019-0.01027-1e-3 -0.0128l0.0031-0.0027c9.6e-5 -4.1806 0.0019-4.1805-1e-3 -5.2891 0.0021-0.43716-0.0128-10.921-0.0128-10.921h-5.23e-4zm-10.848 1.0007a7.466 7.466 0 0 0-7.466 7.466 7.466 7.466 0 0 0 7.466 7.466 7.466 7.466 0 0 0 7.466-7.466 7.466 7.466 0 0 0-7.466-7.466zm-9.242 0.63653c-0.10485 0-0.18362 0.07828-0.18362 0.1831l-0.0067 3.7289c-0.05903 0.0069-0.12471 0.0071-0.19025 0.0071-0.06553 0-0.13127-2.34e-4 -0.19024-0.0071v-3.7223c0-0.10485-0.07827-0.18362-0.1831-0.18362-0.10485 0-0.18362 0.07879-0.18362 0.18362l0.0067 3.6769c-0.16384-0.03277-0.30126-0.07911-0.37335-0.12496v-3.5519c0-0.10485-0.07877-0.18362-0.18362-0.18362s-0.1831 0.07879-0.1831 0.18362l-0.0072 3.6504c0 0.35389 0.1375 0.67508 0.37998 0.91757 0.10485 0.10485 0.22282 0.18329 0.34734 0.24227l-0.13771 9.575c0 0.39322 0.32143 0.72724 0.7212 0.72069 0.19005 0.0069 0.36673-0.07857 0.4978-0.20963 0.13107-0.13107 0.2097-0.31453 0.21626-0.51769l-0.26216-9.5159c0.1704-0.06553 0.3346-0.16374 0.46567-0.2948 0.23592-0.23593 0.37998-0.56367 0.37998-0.91757v-3.6437c0-0.10451-0.07828-0.1831-0.1826-0.18361h-1e-3c-0.10433 5.21e-4 -0.1826 0.07913-0.1826 0.18361l-0.0071 3.5453c-0.03928 0.02625-0.09827 0.04629-0.17036 0.07905-0.05903 0.01964-0.13091 0.03929-0.203 0.04591v-3.6835c0-0.10485-0.07877-0.1831-0.18362-0.1831zm9.242 1.3639c3.0157 0 5.4656 2.4499 5.4656 5.4656 0 3.0157-2.4499 5.4651-5.4656 5.4651s-5.4651-2.4494-5.4651-5.4651c0-3.0157 2.4494-5.4656 5.4651-5.4656zm0 0.46057c-2.7668 0-5.0045 2.2383-5.0045 5.005 0 2.7668 2.2377 5.0045 5.0045 5.0045s5.0051-2.2377 5.0051-5.0045c0-2.7668-2.2383-5.005-5.0051-5.005z
      `}
    />
  </svg>
);

export default PlaceSettingIcon;
