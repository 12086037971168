import * as React from "react";

import DefaultLayout from "../layouts/DefaultLayout";
import BackgroundImage from "../components/BackgroundImage";
import Image from "../components/Image";
import SVGImage from "../components/SVGImage";
import PhoneIcon from "../components/icons/phone";
import PlaceSettingIcon from "../components/icons/place-setting";
import { range } from "lodash";

const PHONE_NUMBER = "0439 866 685";
const PHONE_NUMBER_URL = "tel:+61439866685";

interface HomePageProps {
  data: {
    mdx: {
      body: string;
      frontmatter: {
        title: string;
        summary?: string;
      };
      fields: {
        url: string;
      };
    };
  };
}

const HomePage: React.FC<HomePageProps> = () => {
  return (
    <DefaultLayout pageTitle="On the Moov" isFront>
      <header>
        <BackgroundImage name="header_banner" className="header-banner">
          <div className="py-3 pb-md-5">
            <div className="container">
              <div className="p-3">
                <div className="branding">
                  <SVGImage name="logo" className="mw-100" />
                </div>
              </div>
              <div className="p-3">
                <h1 className="page-title text-center">
                  <span className="sr-only">
                    Servicing the Fleurieu Peninsula and Southern Adelaide Hills
                  </span>
                  <SVGImage name="page_title" className="mw-100" />
                </h1>
              </div>
            </div>
            <div className="container pb-3 pb-sm-5 pt-2">
              <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-8">
                  <p className="h3 text-center">
                    We're the friendly local butcher who comes to you.
                  </p>
                  <p className="h3 text-center">
                    We provide a complete processing service on your farm so you
                    can serve and enjoy your own produce.
                  </p>
                </div>
                <div className="col-lg-2"></div>
              </div>
            </div>
            <div className="container">
              <div className="d-flex justify-content-center pb-5 py-md-3 py-lg-5">
                <CallButton>
                  <div>Bookings and Enquiries</div>
                  <div className="h2 m-0">{PHONE_NUMBER}</div>
                </CallButton>
              </div>
            </div>
          </div>
        </BackgroundImage>
      </header>
      <section className="py-5 py-md-5">
        <SectionTitle>
          We produce lean mince, sausages, and clearly-labelled individual cuts.
        </SectionTitle>
        <div className="container">
          <div className="row">
            <div className="d-none d-md-block col-md-4 p-md-4">
              <Image name="product_image_1" />
            </div>
            <div className="col-md-4 p-3 p-md-4">
              <Image name="product_image_2" />
            </div>
            <div className="d-none d-md-block col-md-4 p-md-4">
              <Image name="product_image_3" />
            </div>
          </div>
        </div>
      </section>
      <section className="py-5 bg-theme-secondary text-primary">
        <SectionTitle>We package your meat the way you need it.</SectionTitle>
        <div className="container">
          <div className="row my-4 my-md-5">
            <div className="col-lg-4">
              <h3 className="text-center text-lg-right py-lg-4">
                Serves for two
              </h3>
            </div>
            <div className="col-lg-8">
              <div className="d-flex flex-nowrap justify-content-center justify-content-lg-start">
                {range(2).map(index => (
                  <div key={index} className="p-3">
                    <PlaceSettingIcon />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="row my-4 my-md-5">
            <div className="col-lg-4">
              <h3 className="text-center text-lg-right py-lg-4">
                Serves for four
              </h3>
            </div>
            <div className="col-lg-8">
              <div className="d-sm-flex justify-content-center justify-content-lg-start">
                {range(2).map(index => (
                  <div
                    key={index}
                    className="d-flex flex-nowrap justify-content-center justify-content-lg-start"
                  >
                    {range(2).map(index => (
                      <div key={index} className="p-3">
                        <PlaceSettingIcon />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="row my-4 my-md-5">
            <div className="col-lg-4">
              <h3 className="text-center text-lg-right py-lg-4">
                Bulk packaging
              </h3>
            </div>
            <div className="col-lg-8">
              <div className="d-sm-flex justify-content-center justify-content-lg-start">
                {range(2).map(index => (
                  <div key={index}>
                    {range(2).map(index => (
                      <div
                        key={index}
                        className="d-flex flex-nowrap justify-content-center justify-content-lg-start"
                      >
                        {range(3).map(index => (
                          <div key={index} className="p-3">
                            <PlaceSettingIcon height={32} width={42} />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <KnifeListItem>
                <h2 className="h3">We cut the meat the way you want it</h2>
                <p>fast and efficient service</p>
              </KnifeListItem>
            </div>
            <div className="col-md-6">
              <KnifeListItem>
                <h2 className="h3">Experienced local butcher</h2>
                <p>providing a unique and professional service</p>
              </KnifeListItem>
            </div>
            <div className="col-md-6">
              <KnifeListItem>
                <h2 className="h3">On-site mobile coolrooms</h2>
                <p>hanging period of 7 or 14 days</p>
              </KnifeListItem>
            </div>
            <div className="col-md-6">
              <KnifeListItem>
                <h2 className="h3">Undisturbed environment</h2>
                <p>no need for abattoirs</p>
              </KnifeListItem>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <div className="container">
          <div className="text-center">
            <div className="pb-3">
              <h3>For bookings or enquiries, call us.</h3>
            </div>
            <CallButton>
              <div>Warren Somerford</div>
              <div className="h2 m-0">{PHONE_NUMBER}</div>
            </CallButton>
          </div>
        </div>
      </footer>
    </DefaultLayout>
  );
};

const CallButton: React.FC = ({ children }) => (
  <a href={PHONE_NUMBER_URL} className="call-button btn btn-lg btn-primary">
    <div className="d-flex align-items-center">
      <div className="flex-none pr-3">
        <PhoneIcon width={36} height={36} />
      </div>
      <div className="flex-grow-1">{children}</div>
    </div>
  </a>
);

const KnifeListItem: React.FC = ({ children }) => (
  <div className="d-flex pb-5">
    <div className="pr-3 pr-md-4">
      <SVGImage name="knife_icon" />
    </div>
    <div className="flex-grow-1">{children}</div>
  </div>
);

const SectionTitle: React.FC = ({ children }) => (
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-lg-8">
        <h2 className="text-center pb-3">{children}</h2>
      </div>
    </div>
  </div>
);

export default HomePage;
